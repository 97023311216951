<template>
  <div class="home">
    <MainNav/>
    <img alt="Totogi logo" src="../assets/logo.png" style="max-width: 300px">
    <AmplifyAuth />
  </div>
</template>

<script>
// @ is an alias to /src
import AmplifyAuth from '@/components/AmplifyAuth.vue'
import MainNav from '@/components/MainNav.vue'

export default {
  name: 'SignIn',
  components: {
    AmplifyAuth,
    MainNav
  }
}
</script>
