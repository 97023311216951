import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import AmplifyVue from '@aws-amplify/ui-vue'
import CoreuiVue from '@coreui/vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@coreui/coreui/dist/css/coreui.min.css'

// Set correct redirect urls
awsExports.oauth.redirectSignIn = `${window.location.origin}/`
awsExports.oauth.redirectSignOut = `${window.location.origin}/`

Amplify.configure(awsExports)

createApp(App).use(store).use(router).use(AmplifyVue).use(CoreuiVue).use(Amplify).mount('#app')
