<template>
  <div>
    <MainNav/>
    <h1>AWS Marketplace account</h1>
  </div>
</template>

<script>
import MainNav from '@/components/MainNav.vue'

export default {
  name: 'AccountView',
  components: {
    MainNav
  },
  data () {
    return {
      errorModalVisible: false,
      errorMessage: ''
    }
  },
  props: {
    accountId: {
      type: String,
      required: true
    }
  }
}
</script>
