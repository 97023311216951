<template>
  <CNavbar
    expand="lg"
    color-scheme="dark"
    style="background-color: #001D3D"
    placement="fixed-top"
  >
    <!-- ^ Same background color as the Totogi documentation sidebar -->
    <!-- http://docs.api.totogi.com/graphql/#definition-InitialTemplateInstance -->
    <CContainer fluid>
      <CNavbarBrand href="#">
        <img
          src="@/assets/totogi-logo-white.svg"
          alt="Totogi Logo"
          style="max-width: 80px"
          class="d-inline-block align-top"
        />
      </CNavbarBrand>
      <CNavbarNav>
          <CNavItem>
            <CNavLink
              @click="this.$router.push({ name: 'Home' })"
              active
            >
              Home
            </CNavLink>
          </CNavItem>
      </CNavbarNav>
    </CContainer>
  </CNavbar>
</template>

<script>
export default {
  name: 'MainNav',
  props: {
    msg: String
  }
}
</script>
