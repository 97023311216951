import { createStore } from 'vuex'
import { Auth } from 'aws-amplify'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    signOut: async function () {
      try {
        await Auth.signOut()
      } catch (error) {
        console.log('error signing out: ', error)
      }
    },
    isSignedIn: async function () {
      try {
        await Auth.currentUserInfo()
      } catch (error) {
        console.log('error signing out: ', error)
      }
    }
  },
  modules: {
  }
})
