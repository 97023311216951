<template>
  <div>
    <MainNav/>
    <h1>Tototgi AWS Mock Marketplace Accounts</h1>
    <CContainer>
      <CRow class="align-items-center" v-for="(account, key) in accounts"
        :key="key">
        <CCol>
          <CButton
            color="info"
            @click="this.$router.push('/account/' + account.id)">{{ account.id }}</CButton>
        </CCol>
      </CRow>
    </CContainer>
    <CButton color="dark" @click="this.$store.dispatch('signOut')">Sign Out</CButton>
  </div>
</template>

<script>
import MainNav from '@/components/MainNav.vue'
import { Auth } from 'aws-amplify'
import axios from 'axios'

export default {
  name: 'HomeView',
  components: {
    MainNav
  },
  data () {
    return {
      accounts: []
    }
  },
  mounted () {
    this.getAllAccounts()
  },
  methods: {
    getToken: async function () {
      const idToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      return idToken
    },
    listAllAccounts: async function () {
      const token = await this.getToken()
      const result = await axios.post(
        'https://ogegcp1wu0.execute-api.us-east-1.amazonaws.com/events/tenants/get_events',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      this.accounts = result.data.results ?? []
    }
  }
}
</script>
