<template>
<div class="auth">
    <br />
    <h1>Use Social sign in with your Totogi Email</h1>
    <authenticator>
    <template v-slot="{ user }">
        <h1>You are now signed in as: {{ user.attributes.email }}. {{goBack()}}</h1>
    </template>
    </authenticator>
</div>
</template>

<script>
import { Authenticator } from '@aws-amplify/ui-vue'
import '@aws-amplify/ui-vue/styles.css'
import { Auth } from 'aws-amplify'

export default {
  name: 'AmplifyAuth',
  data () {
    return {
      signedIn: false
    }
  },
  updated () {
    console.log('updated!')
  },
  methods: {
    goBack: function () {
      if (typeof this.$route.query.redirect !== 'string') {
        window.location.href = '/'
      } else {
        window.location.href = this.$route.query.redirect
      }
    },
    checkSignedIn: function () {
      Auth.currentAuthenticatedUser()
        .then((result) => {
          console.log('isSignedIn returning true')
          console.log(result)
          this.signedIn = true
        })
        .catch((error) => {
          console.error(error)
          console.log('isSignedIn returning false')
          console.log('caught, returning false')
          this.signedIn = false
        })
    }
  },
  components: {
    Authenticator
  }
}
</script>

<style>
  .auth {
    margin: 0 auto;
    width: 460px;
  }
</style>
